import React from "react"
import MainContainer from "../../components/containers/MainContainer"
import HeadDataNew from "../../data/HeadDataNew"
import VideosSectionUpdate from "../../components/sections/VideosSectionUpdate";

const ExitDeviceKitsVideosPage = () => (
  <MainContainer>
    <HeadDataNew 
      title='Exit Device Kits Videos Page' 
      description='A list of Command Access Exit Device Kits videos.' 
    />

    <VideosSectionUpdate 
      title="Exit Device Kits"
      category="Exit Device Kits"
    />
  </MainContainer>
)

export default ExitDeviceKitsVideosPage